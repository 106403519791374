import styled from 'styled-components';
import mainBackground from 'images/mainBackground.svg';

const Container = styled.div``;

const Hero = styled.div`
  background-image: url(${mainBackground});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 150px;
  > div:last-child {
    display: flex;
    align-items: center;
    > img {
      margin-left: auto;
      max-width: 50%;
      height: auto;
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 150px;
    > div:last-child {
      flex-direction: column;
      align-items: stretch;
      > img {
        align-self: center;
        margin-left: 0px;
        max-width: 50%;
        width: auto;
        max-height: 500px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 125px;
  }

  @media (max-width: 575px) {
    padding-bottom: 100px;
  }
`;

const Info = styled.div`
  background: linear-gradient(270deg, #FB6D2E 0%, rgba(251, 109, 46, 0.447917) 45.83%, rgba(251, 109, 46, 0) 88.02%);
  max-width: 50%;
  padding: 40px 100px;
  flex: 1;
  h2, p {
    color: #ffffff;
  }
  h2 {
    max-width: 520px;
    margin-bottom: 35px;
  }
  p {
    max-width: 450px;
    font-size: 24px;
    margin-bottom: 70px;
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: 28px;
    }
    padding: 20px 45px;
  }

  @media (max-width: 1024px) {
    max-width: none;
    flex: none;
    text-align: center;
    h2 {
      margin: 0px auto 35px auto;
    }
    p {
      margin: 0px auto 70px auto;
    }
    button {
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
    h2 {
      font-size: 24px;
      margin-bottom: 25px;
    }
    p {
      font-size: 20px;
      margin-bottom: 50px;
    }
  }

  @media (max-width: 575px) {
    h2 {
      font-size: 20px;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
`;

const Features = styled.div`
  padding: 80px 20px 110px 20px;
  h2 {
    text-align: center;
    margin-bottom: 105px;
  }
  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    div {
      margin: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 180px;
      font-size: 24px;
      text-align: center;
      img {
        margin-bottom: 50px;
        height: 100px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 40px 20px 55px 20px;
    h2 {
      margin-bottom: 50px;
    }
    > div {
      div {
        margin: 20px;
        font-size: 20px;
        img {
          margin-bottom: 25px;
          height: 80px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    > div {
      div {
        margin: 16px;
        font-size: 16px;
        img {
          margin-bottom: 15px;
          height: 60px;
        }
      }
    }
  }
`;

const BottomContainer = styled.div`
  position: relative;
  > img {
    position: absolute;
    top: ${(props) => props.imageOffset}px;
    height: auto;
    max-width: 840px;
    width: 100%;
  }
`;

const MobileApp = styled.div`
  display: flex;
  padding: 250px 20px 110px 20px;
  justify-content: center;
  align-items: center;
  > div {
    max-width: 635px;
    margin-right: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    > p {
      margin-bottom: 60px;
      :first-child {
        margin-bottom: 24px;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;
    }
  }

  @media (max-width: 1240px) {
    flex-direction: column;
    > div {
      text-align: center;
      margin-right: 0px;
      > div {
        margin-bottom: 50px;
      }
    }
    > img {
      max-width: 100%;
    }
   }

  @media (max-width: 768px) {
    padding: 220px 10px 55px 10px;
    > div {
      font-size: 20px;
      > p {
        margin-bottom: 45px;
        :first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  
  @media (max-width: 575px) {
    padding: 190px 10px 55px 10px;
    > div {
      font-size: 16px;
      > p {
        margin-bottom: 30px;
        :first-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 160px 10px 55px 10px;
  }

  @media (max-width: 400px) {
    padding: 140px 10px 55px 10px;
  }
`;

export {
  Container,
  Hero,
  Info,
  Features,
  BottomContainer,
  MobileApp,
};

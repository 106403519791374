import { Form } from 'formik';
import styled from 'styled-components';

const FormContainer = styled(Form)`
  box-sizing: border-box;
  max-width: 490px;
  margin: 0 auto;
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 575px) {
    padding: 24px;
    font-size: 16px;
  }
`;

const FormHeader = styled.div`
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 575px) {
    font-size: 24px;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  white-space: nowrap;

  @media (max-width: 575px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const FormLink = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
`;

export {
  FormContainer, FormHeader, FormFooter, FormButtons, FormLink,
};

import styled from 'styled-components';
import searchBackground from 'images/searchBackground.svg';

const Container = styled.div`
  background-image: url(${searchBackground});
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;

  @media (max-width: 768px) {
    backgound-size: auto; 
  }
`;

const Content = styled.div`
  padding: 0 20px 40px 20px;
  min-height: calc(100% - 398px);

  @media (max-width: 768px) {
    min-height: calc(100% - 368px);
  }
  
  @media (max-width: 575px) {
    padding: 0 10px 20px 10px;
    min-height: calc(100% - 350px);
  }
`;

export { Container, Content };

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { useStore } from 'utils/store';
import { Logo, Dropdown } from 'components/shared';

import { Container } from './styles';

const Header = ({ history: { push } }) => {
  const [{ user }, setStore] = useStore();
  return (
    <Container>
      <Link to="/"><Logo type="inverted" /></Link>
      {user
        ? (
          <Dropdown
            items={['Профиль', 'Настройки', 'Смена пароля', 'Выйти']}
            onSelect={(value) => {
              switch (value) {
                case 'Профиль':
                  push('/profile');
                  break;
                case 'Настройки':
                  push('/settings');
                  break;
                case 'Смена пароля':
                  push('/change-password');
                  break;
                case 'Выйти':
                  localStorage.removeItem('token');
                  setStore({ user: undefined });
                  push('/');
                  break;
                default:
                  break;
              }
            }}
          >
            {user.name && user.surname ? `${user.name} ${user.surname}` : user.phone}
          </Dropdown>
        )
        : <Link to="/auth/check">Вход / Регистрация</Link>}
    </Container>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Input, Button, Modal } from 'components/shared';
import {
  FormButtons, FormContainer, FormFooter, FormHeader,
} from './styles';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .required('Пожалуйста введите пароль'),
  newPassword: Yup.string()
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .required('Пожалуйста введите пароль'),
});

export const ChangePassword = ({
  message,
  isChanging,
  goBack,
  setMessage,
  changePassword,
}) => (
  <>
    <Formik
      validationSchema={validationSchema}
      initialValues={{ oldPassword: '', newPassword: '' }}
      onSubmit={(values) => changePassword({ ...values, role: 'client' })}
      render={() => (
        <FormContainer>
          <FormHeader>Смена пароля</FormHeader>
          <Field
            type="password"
            name="oldPassword"
            placeholder="Старый пароль"
            component={Input}
          />
          <Field
            type="password"
            name="newPassword"
            placeholder="Новый пароль"
            component={Input}
          />
          <FormFooter>
            <FormButtons>
              <Button type="button" onClick={goBack}>
                Назад
              </Button>
              <Button type="submit" primary loading={isChanging}>
                Сохранить
              </Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      )}
    />
    <Modal
      opened={!!message.text}
      header={message.header}
      onClose={() => {
        if (message.onClose) {
          message.onClose();
        }
        setMessage({});
      }}
    >
      {message.text}
    </Modal>
  </>
);

ChangePassword.propTypes = {
  message: PropTypes.object.isRequired,
  isChanging: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { Input, Icon } from 'components/shared';

import { parseDate, formatDate } from './helpers';
import { Container } from './styles';
import './styles.css';

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const WEEKDAYS_LONG = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];

const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const FORMAT = 'dd.MM.yyyy';

// fix ref error(warning)
class DatepickerInput extends PureComponent {
  render() {
    return (
      <Container>
        <Icon type="calendar" />
        <Input {...this.props} />
      </Container>
    );
  }
}

export const Datepicker = ({ value, onChange }) => (
  <DayPickerInput
    style={{ display: 'block' }}
    placeholder="Дата"
    value={value}
    onDayChange={onChange}
    component={DatepickerInput}
    formatDate={formatDate}
    format={FORMAT}
    parseDate={parseDate}
    dayPickerProps={{
      locale: 'ru',
      months: MONTHS,
      weekdaysLong: WEEKDAYS_LONG,
      weekdaysShort: WEEKDAYS_SHORT,
      firstDayOfWeek: 1,
      modifiersStyles: {
        Overlay: {
          zIndex: '100',
        },
      },
    }}
  />
);

Datepicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
};

Datepicker.defaultProps = {
  value: '',
  onChange: PropTypes.func,
};

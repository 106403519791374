import styled, { css } from 'styled-components';
import profileBackground from 'images/profileBackground.svg';

const Container = styled.div`
  background-image: url(${profileBackground});
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
`;

const Content = styled.div`
  padding: 0 20px 40px 20px;
  min-height: calc(100% - 398px);

  @media (max-width: 575px) {
    padding: 0px 10px 20px 10px;
  }
`;

const ProfileCard = styled.div`
  max-width: 1360px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px 30px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 575px) {
    padding: 10px 15px;
  }
`;

const Left = styled.div`
  background: linear-gradient(140.07deg, rgba(204, 58, 255, 0.7) -6.86%, rgba(255, 255, 255, 0) 47.68%), linear-gradient(86.13deg, #6401B3 21.69%, #30D6FD 110.92%);
  border-radius: 20px;
  width: 300px;
  margin-right: 60px;

  @media (max-width: 1024px) {
    width: auto;
    margin-right: 0px;
  }
`;

const UserInfo = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  img {
    border-radius: 100%;
    margin-bottom: 30px;
  }
  h3 {
    color: #ffffff;
    margin-bottom: 10px;
  }
  span {
    color: #ffffff;
    font-size: 21px;
  }
`;

const Tabs = styled.div`
  position: relative;
  padding: 40px 20px;
  margin-bottom: 60px;
`;

const Tab = styled.div`
  border-radius: 20px;
  padding: 15px 20px;
  position: relative;
  font-size: 24px;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
  ${(props) => props.active && css`
    color: #000000;
    font-weight: 600;
    background-color: #ffffff;
  `}

  @media (max-width: 768px) {
   font-size: 20px;
  }
`;

const Right = styled.div`
  flex: 1;
  h2 {
    font-size: 36px;
    text-transform: none;
    margin: 30px 0px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 24px;
      margin: 20px 0px;
    }
  }
`;

const TicketWrapper = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 35px;
  }
  > div:first-child {
    flex: 1;
  }
  
  @media (max-width: 1320px) {
    display: block;
  }

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 900px) {
    display: block;
  }
`;

const TicketInfo = styled.div`
  margin-left: 15px;
  width: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button {
    margin: 0px 0px 30px 0px;
  }

  @media (max-width: 1320px){
    margin-left: 0px;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    button {
      margin: 20px 5px 0px 5px;
    }
  }

  @media (max-width: 1024px){
    margin-left: 15px;
    width: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
      margin: 0px 0px 30px 0px;
    }
  }

  @media (max-width: 900px){
    margin-left: 0px;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    button {
      margin: 20px 5px 0px 5px;
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 10px;
  }
`;

const Card = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px 30px;

  :not(:last-child) {
    margin-bottom: 35px;
  }

  div {
    :not(:last-child) {
      margin-bottom: 15px;
    }
    span {
      font-size: 21px;
    }
    span:first-child {
      margin-right: 10px;
    }
    span:last-child {
      color: #606060;
    }
  }

  @media (max-width: 768px) {
    div {
      :not(:last-child) {
        margin-bottom: 10px;
      }
      span {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 575px) {
    div {
      :not(:last-child) {
        margin-bottom: 5px;
      }
      span {
        font-size: 16px;
      }
    }
    a {
      font-size: 16px;
    }
  }
`;

export {
  Container,
  Content,
  ProfileCard,
  Left,
  Tabs,
  Tab,
  UserInfo,
  Right,
  TicketWrapper,
  TicketInfo,
  ModalFooter,
  Card,
};

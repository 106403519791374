import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Input, Button } from 'components/shared';
import {
  FormButtons, FormContainer, FormFooter, FormHeader,
} from './styles';

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+375(29|33|44|25)\d{7}/,
      'Пожалуйста введите номер телефона в формате +375XXYYYYYYY',
    )
    .required('Пожалуйста введите номер телефона'),
});

export const AuthForgotPassword = ({
  phone,
  message,
  isSending,
  back,
  setMessage,
  send,
}) => (
  <>
    <Formik
      validationSchema={validationSchema}
      initialValues={{ phone }}
      onSubmit={(values) => send({ ...values, role: 'client' })}
      render={() => (
        <FormContainer>
          <FormHeader>Сброс пароля</FormHeader>
          <Field
            type="tel"
            name="phone"
            placeholder="Номер телефона"
            component={Input}
          />
          <FormFooter>
            <FormButtons>
              <Button type="button" onClick={() => back()}>
                Назад
              </Button>
              <Button type="submit" loading={isSending}>
                Получить код
              </Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      )}
    />
    <Modal
      opened={!!message.text}
      header={message.header}
      onClose={() => {
        if (message.onClose) {
          message.onClose();
        }
        setMessage({});
      }}
    >
      {message.text}
    </Modal>
  </>
);

AuthForgotPassword.propTypes = {
  phone: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  isSending: PropTypes.bool.isRequired,
  back: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
};

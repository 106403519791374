import lazy from 'utils/lazy';

const AuthCheckPage = lazy(() => import('./auth-check'));
const AuthConfirmPage = lazy(() => import('./auth-confirm'));
const AuthForgotPasswordPage = lazy(() => import('./auth-forgot-password'));
const AuthResetPasswordPage = lazy(() => import('./auth-reset-password'));
const AuthSignInPage = lazy(() => import('./auth-sign-in'));
const AuthSignUpPage = lazy(() => import('./auth-sign-up'));
const BookingPage = lazy(() => import('./booking'));
const CarriersPage = lazy(() => import('./carriers'));
const ChangePasswordPage = lazy(() => import('./change-password'));
const ContactsPage = lazy(() => import('./contacts'));
const LegalPage = lazy(() => import('./legal'));
const LoginPage = lazy(() => import('./login'));
const ProfilePage = lazy(() => import('./profile'));
const RegistrationPage = lazy(() => import('./registration'));
const MainPage = lazy(() => import('./main'));
const SearchPage = lazy(() => import('./search'));
const SettingsPage = lazy(() => import('./settings'));

export {
  AuthCheckPage,
  AuthConfirmPage,
  AuthForgotPasswordPage,
  AuthResetPasswordPage,
  AuthSignInPage,
  AuthSignUpPage,
  BookingPage,
  CarriersPage,
  ChangePasswordPage,
  ContactsPage,
  LegalPage,
  LoginPage,
  ProfilePage,
  RegistrationPage,
  MainPage,
  SearchPage,
  SettingsPage,
};

export { default as NotFoundPage } from './not-found';

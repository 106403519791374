import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Input, Button } from 'components/shared';
import {
  FormButtons,
  FormContainer,
  FormFooter,
  FormHeader,
  FormLink,
} from './styles';

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+375(29|33|44|25)\d{7}/,
      'Пожалуйста введите номер телефона в формате +375XXYYYYYYY',
    )
    .required('Пожалуйста введите номер телефона'),
});

export const AuthCheck = ({
  phone,
  message,
  isChecking,
  setMessage,
  check,
}) => (
  <>
    <Formik
      validationSchema={validationSchema}
      initialValues={{ phone }}
      onSubmit={(values) => check({ ...values, role: 'client' })}
      render={(formProps) => (
        <FormContainer>
          <FormHeader>Вход</FormHeader>
          <Field
            type="tel"
            name="phone"
            placeholder="Номер телефона"
            component={Input}
          />
          <FormFooter>
            <FormButtons>
              <Button type="submit" loading={isChecking}>
                Далее
              </Button>
            </FormButtons>
            <FormLink>
              Первый раз в системе?
              <Link to={`/auth/sign-up?phone=${formProps.values.phone}`}>
                Зарегистрируйтесь
              </Link>
            </FormLink>
          </FormFooter>
        </FormContainer>
      )}
    />
    <Modal
      opened={!!message.text}
      header={message.header}
      onClose={() => setMessage({})}
    >
      {message.text}
    </Modal>
  </>
);

AuthCheck.propTypes = {
  phone: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  isChecking: PropTypes.bool.isRequired,
  setMessage: PropTypes.func.isRequired,
  check: PropTypes.func.isRequired,
};

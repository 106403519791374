import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import LogRocket from 'logrocket';
import ym from 'react-yandex-metrika';
import { AuthSignIn } from 'components';
import api from 'utils/api';
import { useStore } from 'utils/store';

const signInRequest = ([body]) => api.post('/auth/signIn', body);

const SignInPage = ({ history: { replace }, location: { search } }) => {
  const [{ reservation }, setStore] = useStore();
  const [message, setMessage] = useState({});

  const { isLoading: isSigningIn, run: signIn } = useAsync({
    deferFn: signInRequest,
    onResolve: ({ user, token }) => {
      ym('reachGoal', 'login');
      setStore({ user });
      localStorage.setItem('token', token);
      LogRocket.identify(user.phone, {
        name: user.name,
        surname: user.surname,
      });
      if (reservation) {
        replace('/booking');
      } else {
        replace('/');
      }
    },
    onReject: (error) => {
      switch (error.message) {
        case 'err_user_not_found':
          setMessage({
            header: 'Ошибка',
            text: 'Пользователь с таким номером телефона не найден',
          });
          break;
        case 'err_password_not_set':
          setMessage({
            header: 'Ошибка',
            text: 'Для этого пользователя не установлен пароль',
          });
          break;
        case 'err_password_invalid':
          setMessage({
            header: 'Ошибка',
            text: 'Неверный пароль',
          });
          break;
        case 'err_rate_limit':
          setMessage({
            header: 'Ошибка',
            text: 'Слишком много попыток входа. Попробуйте позже',
          });
          break;
        default:
          setMessage({ header: 'Ошибка', text: error.message });
          break;
      }
    },
  });

  const params = new URLSearchParams(search);
  const phone = params.get('phone') ? `+${params.get('phone').trim()}` : '';
  const back = () => replace(`/auth/check?phone=${phone}`);

  const AuthSignInProps = {
    phone,
    message,
    isSigningIn,
    back,
    setMessage,
    signIn,
  };

  return <AuthSignIn {...AuthSignInProps} />;
};

SignInPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SignInPage;

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Input,
  Button,
  Modal,
} from 'components/shared';
import {
  FormContainer,
  FormHeader,
} from './styles';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[А-яЁё]+$/, 'Пожалуйста введите имя содержащее только русские буквы')
    .required('Пожалуйста введите свое имя'),
  surname: Yup.string()
    .matches(/^[А-яЁё]+$/, 'Пожалуйста введите фамилию содержащее только русские буквы')
    .required('Пожалуйста введите свою фамилию'),
});

const Settings = ({
  phone,
  name,
  surname,
  message,
  isLoading,
  goBack,
  setMessage,
  editSettings,
}) => (
  <>
    <FormContainer>
      <FormHeader>Настройки</FormHeader>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ phone, name, surname }}
        onSubmit={(values) => editSettings({ client: values })}
        render={() => (
          <Form>
            <Field name="name" placeholder="Имя" component={Input} />
            <Field name="surname" placeholder="Фамилия" component={Input} />
            <div>
              <Button
                type="button"
                onClick={goBack}
              >
                Назад
              </Button>
              <Button
                type="submit"
                primary
                loading={isLoading}
              >
                Сохранить
              </Button>
            </div>
          </Form>
        )}
      />
    </FormContainer>
    <Modal
      opened={!!message.text}
      header={message.header}
      onClose={() => {
        if (message.onClose) {
          message.onClose();
        }
        setMessage({});
      }}
    >
      {message.text}
    </Modal>
  </>
);

Settings.propTypes = {
  phone: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  editSettings: PropTypes.func.isRequired,
};

export default Settings;

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Input, Button } from 'components/shared';
import {
  FormButtons, FormContainer, FormFooter, FormHeader,
} from './styles';

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+375(29|33|44|25)\d{7}/,
      'Пожалуйста введите номер телефона в формате +375XXYYYYYYY',
    )
    .required('Пожалуйста введите номер телефона'),
  pin: Yup.string()
    .length(4, 'Код должен содержать 4 цифры')
    .required('Пожалуйста введите код'),
});

export const AuthConfirm = ({
  phone,
  message,
  isConfirming,
  back,
  setMessage,
  confirm,
}) => (
  <>
    <Formik
      validationSchema={validationSchema}
      initialValues={{ phone, pin: '', password: '' }}
      onSubmit={(values) => confirm({ ...values, role: 'client' })}
      render={() => (
        <FormContainer>
          <FormHeader>Регистрация</FormHeader>
          <Field
            type="tel"
            name="phone"
            placeholder="Номер телефона"
            component={Input}
          />
          <Field
            inputMode="numeric"
            autoComplete="none"
            name="pin"
            placeholder="Код"
            component={Input}
            onWheel={(e) => e.target.blur()}
          />
          <FormFooter>
            <FormButtons>
              <Button type="button" onClick={() => back()}>
                Назад
              </Button>
              <Button type="submit" loading={isConfirming}>
                Завершить
              </Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      )}
    />

    <Modal
      opened={!!message.text}
      header={message.header}
      onClose={() => {
        if (message.onClose) {
          message.onClose();
        }
        setMessage({});
      }}
    >
      {message.text}
    </Modal>
  </>
);

AuthConfirm.propTypes = {
  phone: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  isConfirming: PropTypes.bool.isRequired,
  back: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};
